<template>
    <div class="main-webinar">
        <h3>Topic</h3>
        <p v-html="webinar.topic"></p>
        <!-- <h3>Description:</h3>
        <p v-html="webinar.content.description"></p> -->
        <h3>Speaker</h3>
        <div class="speaker-photo-container">
            <img  :src="`${$store.state.URL}avatars/${webinar.speaker_avatar}`" alt="speaker photo" />
        </div>
        <p class="speaker-name">{{ `${webinar.speaker_title} ${webinar.firstname} ${webinar.lastname}`}}</p>
        <p v-html="webinar.speaker_description"></p>
        <!-- <template v-if="full">
            <h3 >Date & Hour</h3>
            <p class="webinar-date">{{ dateStart.toLocaleDateString() }} - {{ hoursStart }} (CET)</p>
        </template> -->
    </div>
</template>

<script>
export default {
    name : 'WebinarInfos',
    props: ['webinar', 'full'],
    data() {
        return {
            dateStart : new Date(this.webinar.date_start),
            hoursStart : null 
        }
    },
    mounted() {
        const hours = new Date(this.webinar.date_start).getHours();
        let minutes = new Date(this.webinar.date_start).getMinutes();
        if(minutes < 10 ) {
            minutes = '0' + minutes
        }
        if(hours > 12 ) {
            this.hoursStart = hours - 12 + ':' + minutes + 'PM';
        }else {
            this.hoursStart = hours + ':' + minutes + 'AM';
        }
    },
}
</script>

<style lang="scss" scoped>

.main-webinar {
    width:calc(90% - 100px);
    margin:40px 5% 40px 5%;
    padding:20px 50px;
    border:1px solid $secondaryColor;
    border-radius : $radius;
}

.main-webinar h3 {
    margin:0;
    text-align: center;
    color:$secondaryColor;
    font-size:20px;
    font-family: $fontBold;
}

.main-webinar p {
    text-align: center;
    color:$mainColor;
    margin-bottom:30px;
}

.speaker-photo-container {
    height:100px;
    width:100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:50%;
    margin:10px auto 5px;
}

.speaker-photo-container img {
    width:100px;
    height:100px;
}

p.speaker-name {
    margin-bottom: 10px;
    font-family: $fontBold;
}

.webinar-date {
    margin-top:10px;
    font-family: $fontBold;
    font-size:20px;
}

@media screen and (max-width: 768px) {
    .main-webinar {
        margin: 0 0 20px 0;
        width:calc(100% - 50px);
        padding:20px 25px;
    }
}

</style>

