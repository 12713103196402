<template>
    <div class="main" v-if="webinar != null">
        <div class="error" v-if="errorServer" >
            <img src="./../assets/images/icon-error.png" alt="error" />
            <p v-html="errorServer"></p>
        </div>
        <div class="row">
            <div class="title-container">
                <h2>
                    <span> {{ webinar.name }}</span> <br /> <span class="webinar-lang">{{ session }}</span>
                </h2>
            </div>
        </div>
        <div class="row row-content">
            <div class="col2">

                <WebinarInfos :webinar="webinar" :full="true"></WebinarInfos>
            </div>
            <div class="col2">
                <template v-if="available">
                    <template v-if="!$store.state.AUTH.is_loged">              
                        <div class="login-container" v-if="login">
                            <p class="login-info">
                                You must be logged in to register to this webinar
                            </p>
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(loginForm)" >
                                    <ValidationProvider  class="input-login"  rules="required|max:100|email" v-slot="{ errors }">
                                        <label>Email address</label>
                                        <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="email" />
                                        <span class="notice-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                    <ValidationProvider  class="input-login"  rules="required|max:100" v-slot="{ errors }">
                                        <label>Password</label>
                                        <input type="password" :class="{ 'input-error' : errors[0]}"  v-model="pwd" />
                                        <span class="notice-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                    <input type="submit" class="btn-form" value="LOGIN" />
                                    <div class="form-failed" v-if="error" v-html="errorMsg"></div>
                                </form>
                            </ValidationObserver>
                            <p>Don’t have an account yet? <span class="link-create" @click="login = false">Create an account</span></p>
                        </div>
                        <div class="register-container" v-else>
                            <RegisterForm v-on:back="login = true" :webinarRegister="true"></RegisterForm>
                            <p class="back-register" @click="login = true">Back</p>
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="!resgisterSucces">
                            <p class="login-info">Please complete this form to register for this webinar</p>
                            <form class="form-questions"  @submit.prevent="register" id="register">
                                <input type="hidden" name="user_id" :value="vm.$store.state.AUTH.user_id" />
                                <input type="hidden" name="webinar_id" :value="vm.$route.params.id" />
                                <div class="form-questions-container">
                                    <div class="input-questions" v-for="(item, idx) in questions" :key="idx">
                                        <div class="input-container"  :vid="item.webinar_form_questions_id">
                                            <label v-html="item.webinar_form_questions "></label>
                                            <input type="text"  :name="'question[' + item.webinar_form_questions_id + ']'" maxlength="150" />
                                        </div>
                                    </div>
                                </div>
                                <input v-if="!loading" type="submit" class="btn-form" value="REGISTER" />
                                <Loader v-else></Loader>
                                <div class="form-failed" v-if="registerError" v-html="registerError"></div>
                            </form>
                        </template>
                        <template v-else>
                            <div class="form-succes" v-if="resgisterSucces">Your registration has been sent.<br />You will be notified when approved.</div>
                        </template>
                    </template>
                </template>
                <template v-else>
                    <p>This webinar is expired.</p>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import store from './../store'
import WebinarInfos from './../components/WebinarInfos'
import RegisterForm  from './../components/RegisterForm'
import axios from 'axios'
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import Loader from './../components/Loader'
import router from './../router'


export default {
    name : 'Register',
    components : {
        WebinarInfos, ValidationProvider, ValidationObserver, RegisterForm, Loader
    },
    data() {
        return {
            webinar : null,
            login : true,
            email : null,
            pwd : null,
            error : false,
            errorMsg : null,
            questions : null,
            vm : this,
            available : true,
            registerError : null,
            resgisterSucces : false,
            errorServer : null,
            loading: false
        }
    },
    computed: {
        session() {
            if(this.webinar.lang == 2){
                return 'Session française';
            }else {
                return 'English session';
            }
        }
    },
    mounted() {
        let vm = this
        axios
            .get(store.getters.getURL + "webinars/getWebinar.php?id=" + this.$route.params.id )
            .then(function (response) { 
                if(response.data.state == 'success') {
                    vm.webinar = response.data.res
                    vm.questions = response.data.questions
                    vm.available = response.data.available
                }else {
                    if(response.data.type == 1) {
                        router.push('/login')
                    }else {
                        vm.errorServer = "Impossible de récupérer le Webinar " + vm.$route.params.id
                    }
                }
            })
            .catch((err) => {
                vm.errorServer = "Impossible de récupérer le Webinar " + vm.$route.params.id + " : " + err
            });
    },
    methods : {
        loginForm() {
            this.error = false
            this.errorMsg = null
            let vm = this

            axios
                .get(store.getters.getURL + "user/login.php?email=" + this.email + '&pwd=' + this.pwd )
                .then(function (response) { 
                    if(response.data.state == 'success') {
                        let user = {
                            type : response.data.user_type,
                            id : response.data.user_id,
                             token : response.data.token
                        }
                        vm.$store.commit('login', user)
                    }else {
                        vm.error = true
                        vm.errorMsg = response.data.retour
                    }
                })
                .catch((err) => {
                    this.error = true
                    this.errorMsg = "An error has occured: " +  err
                });
        },
        register() {
            this.loading = true
            this.registerError = null
            this.resgisterSucces = false
            let vm = this

            let form = document.getElementById('register')
            let formData = new FormData(form);
            formData.append('token', store.state.JWT_TOKEN)

            axios
                .post(store.getters.getURL + "webinars/register.php", formData )
                .then(function (response) { 
                    vm.loading = false
                    if(response.data.state == 'success') {
                        vm.resgisterSucces = true
                    }else {
                        vm.registerError = response.data.retour
                    }
                })
                .catch((err) => {
                    this.registerError = "An error has occured: " +  err
                });
         
        }
    }
    
}
</script>

<style lang="scss" scoped>
.main {
    width:1450px;
    max-width: 95%;
    margin:50px auto 0 auto;
    display: flex;
    flex-direction:column;
    align-items: center;
}

h2 {
    color:$mainColor;
    font-size:30px;
    width:90%;
    margin:0 auto 20px auto;
    text-align: center;
    font-weight:400;
}

.form-failed {
    max-width:400px;
    text-align: center;
}

h2 span {
    font-family: $fontBold;
}

.webinar-lang {
    font-size:25px;
    font-family: $font;
    font-style:italic;
}

.login-info {
    text-align: center;
    color: $secondaryColor;
    font-size:18px;
}

.row-content {
    flex-direction: row-reverse;
    // align-items: center;
    margin-bottom:80px;
}

.title-container {
    display: flex;
    width: 100%;
    margin-bottom: 0;
}


//login

.link-create {
    color:$mainColor;
}

.link-create:hover {
    text-decoration: underline;
    cursor: pointer;
}

.login-container {
    margin-top: 50px;
}

.login-container p {
    text-align: center;
    margin-top:20px;
    color:$secondaryColor;
}

form {
    margin-top:20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom:20px;
}

.input-login  label {
    color:$secondaryColor;
    font-size:20px;
    margin-bottom:10px;
}

.input-login input {
    color:$secondaryColor;
    font-size:15px;
    width:300px;
    max-width: 90%;
    border:1px solid $secondaryColor;
    border-radius:$radius;
    padding:10px;
}

.input-login input:focus {
    outline: none;
}

.back-register {
    text-align: center;
}



//webinar form
.form-questions-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom:20px;
}

.input-questions {
    width:98%;
    margin: 10px  auto;
    max-width: 600px;
}

@media screen and (max-width: 768px) {
 .row.row-content {
     flex-direction: column;
 }

 .form-questions-container {
     flex-direction: column;
     margin:0 0 20px 0;
 }

 .input-questions {
     width: 98%;
 }

 .input-container {
     width: 100%;
 }
}
</style>